import React, { useState } from "react"
import theme from "prism-react-renderer/themes/oceanicNext"
import Highlight, { defaultProps } from "prism-react-renderer"
import Clipboard from "react-clipboard.js"
import "./Code.css"

import { LiveProvider, LiveEditor, LiveError, LivePreview } from "react-live"

const Code = ({
  children,
  codeString,
  className = "language-js",
  ...props
}) => {
  const language = className.replace(/language-/, "")
  if (props.live) {
    return (
      <LiveProvider code={children.trim()} theme={theme} noInline>
        <div className="example-warper">
          <LivePreview />
        </div>
        <LiveEditor />
        <LiveError />
      </LiveProvider>
    )
  }
  const [copySuccess, setCopySuccess] = useState("")
  const copyToClipboard = e => {
    e.target.focus()
    setCopySuccess(" done!")
    setTimeout(() => {
      setCopySuccess("")
    }, 500)
  }

  return (
    <Highlight
      {...defaultProps}
      code={children.trim()}
      language={language}
      theme={theme}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre className={className} style={{ ...style, padding: "20px" }}>
          <Clipboard
            className="copyText"
            onClick={copyToClipboard}
            data-clipboard-text={tokens
              .map((a, i) => {
                return a
                  .map(v => {
                    return v.content
                  })
                  .join("")
              })
              .join("\n")}
            button-title="Code Copy"
          >
            Copy{copySuccess}
          </Clipboard>
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  )
}

export default Code
